import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <div className="flex flex-col items-center">
      <Seo title="404: Not found" />
      <StaticImage className="" alt="Cocktail Pantry Search logo" src="../images/cps-logo.jpg"
        height={200}
        width={200}
      ></StaticImage>
      <h1 className="text-2xl">This recipe does not exist</h1>
      <p className="mt-4">
        To search for our recipes use our main page at  &nbsp;
        <Link to="/" className="text-blue-700 font-bold">
          Cocktail Pantry Search
        </Link>
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
